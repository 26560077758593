// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDrOxWqnd3TDFlo-_rADj3rEyv8cGmifGg",
  authDomain: "projecu-dd730.firebaseapp.com",
  projectId: "projecu-dd730",
  storageBucket: "projecu-dd730.appspot.com",
  messagingSenderId: "1018757320187",
  appId: "1:1018757320187:web:f655397af7367254808f5e"
};

// Initialize Firebase
export const FIREBASE_APP = initializeApp(firebaseConfig);
export const FIRESTORE_DB = getFirestore(FIREBASE_APP);