import React from 'react'
import styles from '../../styles/MainStyles.module.css'; // Import css modules stylesheet as styles
import Logo from './Logo';


const ProjectInfo = ({ project }) => {
    return (
        <div className={styles.dropShadowMain} style={{ backgroundColor: "#F5F5F5", borderRadius: 11, overflow: 'hidden', width: "100%" }}>
            <div style={{ padding: '14px 20px 10px 20px', backgroundColor: '#E3E3E3', fontWeight: 600, flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <a href='https://projecu.com'><Logo colour={'grey'} width={80} /></a>
                </div>
                <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                    -
                </div>
            </div>

            <div style={{ padding: '20px 20px' }}>
                <span style={{ fontWeight: 'bold' }}>Project: </span>{project.projectTitle}
            </div>
            {/* <table cellSpacing={0}>
                    <tbody>
                        <tr>
                            <td className={styles.tdkey}>
                                Project:
                            </td>
                            <td className={styles.tdval}>
                                {project.projectTitle}
                            </td>
                        </tr>
                    </tbody>
                </table> */}
        </div>
    )
}

export default ProjectInfo