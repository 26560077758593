import React, { useEffect } from 'react'
import ProjectInfo from './ProjectInfo'
import Update from './Update'
import { useState } from 'react'
import styles from '../../styles/MainStyles.module.css'; // Import css modules stylesheet as styles
import Logo from './Logo'


const Page = ({ page, project, updates }) => {

    const [pageType, setPageType] = useState(page);
    const [secretKey, setSecretKey] = useState('');

    useEffect(() => {
        setPageType(page)
        console.log(project)
    }, [page])

  

    const handleKeyCheck = (key) => {
        if (key) {
            if (key === project.projectKey) {
                setPageType('Public')
            } else {
                alert('Secret key incorrect...')
            }
        } else {
            alert('Please enter a vaild key')
        }
    }

    if (pageType === "Loading" && !project)
        return (
            <div>Loading...</div>
        )

    if (pageType === "Public")
        return (
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', padding: 20 }}>
                <div style={{ width: 700, display: 'flex', gap: 20, flexDirection: 'column', alignContent: 'center', alignItems: 'center' }}>
                    {/* <Header /> */}
                    {project && <ProjectInfo project={project} />}
                    {updates?.map((update, index) => {
                        if (update?.status === "Published") {
                            return (
                                <Update key={index} update={update} />
                            )
                        } else {
                            return null
                        }
                    })}
                    <div>
                        <a href='https://projecu.com'><Logo colour={'lightgrey'} width={80} /></a>
                    </div>
                </div>
            </div>
        )
    if (pageType === "Key")
        return (
            <div style={{ display: 'flex', height: '80vh', padding: '10vh', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: 20 }}>
                    <div style={{ fontSize: 22 }}>Logo or Profile</div>
                    <div>Please enter the secret key to view this project. &nbsp;</div>
                    <div style={{ display: 'flex', gap: 10, flexDirection: 'row' }}>
                        <div className={styles.custom_input}>
                            <svg xmlns="http://www.w3.org/2000/svg" className={styles.svg_icon} viewBox="0 0 16 16">
                                <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z"></path>
                                <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                            </svg>
                            <input
                                className={styles.input}
                                type='text'
                                onChange={(e) => setSecretKey(e.target.value)}
                                placeholder='secret key'
                            />
                        </div>
                        <button className={[styles.button]} onClick={() => handleKeyCheck(secretKey)}>Enter</button>
                    </div>
                    <div style={{ textAlign: 'center', fontSize: 11 }}>If you are having issues with accessing <br />this project, please contact the project owner.</div>
                </div>
                <div>
                    <a href='https://projecu.com'><Logo colour={'lightgrey'} width={80} /></a>
                </div>
            </div>
        )

    if (pageType === "Private")
        return (
            <div style={{ display: 'flex', height: '80vh', padding: '10vh', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: 20 }}>
                    <div style={{ fontSize: 22 }}>Logo or Profile</div>
                    <div style={{ textAlign: 'center' }}>This Project is set to private. <br />Please contact the project owner.</div>
                </div>
                <div>
                    <a href='https://projecu.com'><Logo colour={'lightgrey'} width={80} /></a>
                </div>
            </div>
        )

    if (pageType === "NoProject")
        return (
            <div style={{ display: 'flex', height: '80vh', padding: '10vh', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: 20 }}>
                    <div style={{ textAlign: 'center' }}>This project does not exist.</div>
                </div>
                <div>
                    <a href='https://projecu.com'><Logo colour={'lightgrey'} width={80} /></a>
                </div>
            </div>
        )
}

export default Page