import React from 'react'
import styles from '../styles/MainStyles.module.css'; // Import css modules stylesheet as styles
import logo from '../assets/logo.png';
import Logo from './components/Logo';

const HomePage = () => {
    return (
        <div className={styles.backgroundGrad} style={{ backgroundColor: "#fdefbc", width: "100%", height: "100vh", }}>
            <div style={{ display: 'flex', alignItems: 'center', height: '77%', justifyContent: 'center' }}>
                <div className={styles.container_center_flex}>
                    <div>
                       <Logo colour={'#494949'} width={300}/>
                    </div>
                    <div style={{color: '#494949', fontSize: "28px"}}>
                        Project sharing — made easy!
                    </div>
                    <div style={{color: '#494949', fontSize: "14px"}}>
                        A new simple and easy to use app coming to you soon...
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HomePage