import React from 'react'
import ReactPlayer from 'react-player'
import styles from '../../styles/MainStyles.module.css'; // Import css modules stylesheet as styles


const Video = ({content}) => {
    return (
        <div className={styles.widgetCard}>
            <div>Video</div>
            <ReactPlayer
                url={content}
                controls={true}
                width="100%"
                height="auto"
            />
        </div>
    )
}

export default Video