import { Route, Routes } from "react-router-dom";
import ProjectPage from "./pages/ProjectPage";
import HomePage from "./pages/HomePage";


function App() {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="/:pid" element={<ProjectPage />} />
    </Routes>
  );
}

export default App;
