import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { FIRESTORE_DB } from '../firebase';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Page from './components/Page';

const ProjectPage = () => {

    const { pid } = useParams();
    const [project, setProject] = useState(null);
    const [updates, setUpdates] = useState(null);
    const [pageType, setPageType] = useState("Loading");

    //load data
    useEffect(() => {
        //load project information
        if (pid) { //check if there is a project id present from url
            getProject().catch((error) => { 
                console.error("hello");
                setPageType("NoProject");
                console.error(error);
            })
        }
    }, [])

    useEffect(() => {
        if (project) {
            if (project.type === "Public") { //check if project is public
                setPageType("Public")
            } else if (project.type === "Private" && project.projectKey != null) { //check if project is private but with a key
                //load this after key is entered.
                setPageType("Key")
            } else { // this project is private.
                setPageType("Private")
            }
            //load updates
            if (project) {
                const objectToArray = Object.values(project.updates)
                const sortedArr = objectToArray.sort((a, b) => b.date - a.date);
                setUpdates(sortedArr)
            }
        }
    }, [project])

    
    //Get project from DB
    const getProject = async () => {
        const projectRef = doc(FIRESTORE_DB, "projects", pid)
        const querySnapshot = await getDoc(projectRef);
        if (querySnapshot.data()) {
            setProject(querySnapshot.data())
        } else {
            setPageType("NoProject");
        }
    }

 
  
        return (
            <HelmetProvider>
                <Helmet>
                    <title>{project?.projectTitle || "No Project Found"}</title>
                    <meta property="og:title" content={project?.projectTitle || "No Project Found"} />
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <Page page={pageType} project={project} updates={updates} />
            </HelmetProvider>
        )
    
}

export default ProjectPage
