export const formatDate = (dateIn) => {

    const dateObj = new Date(dateIn);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString("default", { month: "long" });
    const year = dateObj.getFullYear();
  
    const nthNumber = (number) => {
      if (number > 3 && number < 21) return "th";
      switch (number % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
  
    const date = `${day}${nthNumber(day)} ${month} ${year}`;
    return date;
  
  }